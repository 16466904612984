import Cookies from 'js-cookie';

$( document ).ready( function(){
  if( document.getElementsByClassName( 'notify-bar' ).length > 0 ) {
    let notificationTopBar = document.querySelector( '.notify-bar' );  
    let notificationBarCloseBtn = document.querySelector( '.notify-bar__close' );
    let notificationBarTitle = document.getElementById( 'notify-title' ).innerHTML;

    const setCookie = ( name, value, options ) => {
      Cookies.set( name, value, options );
    };
    
    if( notificationBarCloseBtn != null && notificationTopBar != null ) {
      notificationBarCloseBtn.addEventListener ( 'click',
        function () {
          setCookie( 'hideTitanCloudNotification', '1' );
          setCookie( 'titanCloudNotificationTitle', notificationBarTitle );
          notificationTopBar.style.display = 'none';
        }
      );
    }
  }
} );
