$( document ).ready( function() {
  const notifyTitle = document.getElementById( 'notify-title' );
  const notifyBarTitle = document.querySelector( '.notify-bar__title' );

  if ( notifyTitle?.scrollWidth > notifyBarTitle?.clientWidth ) {
    const clonedTitle = notifyTitle.cloneNode( true );
    notifyTitle.parentNode.insertBefore( clonedTitle, notifyTitle.nextSibling );
    notifyBarTitle.classList.add( 'start-animation' );
  }
} );
  