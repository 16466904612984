$( ( $ ) => { 
  const loadMoreCount = 6;
  const customPartnerFacetLoadMore = () => {
    const partnerCustomLoadMore = $( '.partner__type-row .load-more-row button' );
    if ( partnerCustomLoadMore.length ) {
      const partnerLists = $( '.partner__type-row .partner__list' );
      partnerLists.each( ( index, list ) => {
        let partnerPosts = $( list ).find( '.bs-posts__column' );
        const button = $( list ).closest( '.partner__type-row' ).find( '.load-more-row' );
        if ( partnerPosts.length <= loadMoreCount ) {
          if ( button.length ) {
            button.hide();
          }
        } else {
          button.show();
        }
      } );
      partnerCustomLoadMore.off( 'click' ).on( 'click', ( e ) => {
        const loadMoreButton = $( e.currentTarget );
        const partnersContentRow = loadMoreButton.closest( '.partner__type-row' );
        const loadMoreLabel = loadMoreButton.data( 'load-more-label' );
        const collapseLabel = loadMoreButton.data( 'collapse-label' );
        const hidingPartnerBlocks = partnersContentRow.find( '.partner__list .bs-posts__column:nth-of-type(n + 7)' );
        if ( loadMoreButton.hasClass( 'loaded-content-btn' ) ) {
          $( 'html, body' ).animate( {
            scrollTop: partnersContentRow.offset().top
          }, 100, () => {
            hidingPartnerBlocks.delay( 80 ).hide( 350 );
          } );
          loadMoreButton.removeClass( 'loaded-content-btn' );
          loadMoreButton.html( loadMoreLabel );
        } else {
          hidingPartnerBlocks.show( 350 );
          loadMoreButton.addClass( 'loaded-content-btn' );
          loadMoreButton.html( collapseLabel );
        }
      } );
    }
  };
  
  customPartnerFacetLoadMore();

  document.addEventListener( 'facetwp-loaded', function() {
    customPartnerFacetLoadMore();
  } );
} );