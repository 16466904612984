$( $ => {
  const section = $( '.bs-section--customer-success-logo-slider' );
  const leftColumn = section.find( '.bs-column--left-column' );
  const rightColumn = section.find( '.bs-column--right-column' );
  const windowSize = window.matchMedia( '(min-width: 992px)' );
  const updateColumnHeight = () => {
    
    if ( windowSize.matches ) {
      rightColumn.outerHeight( leftColumn.outerHeight() );
    } else {
      rightColumn.css( 'height', 'auto' );
    }
  };
  
  updateColumnHeight();
  
  $( window ).on( 'resize', () => {
    updateColumnHeight();
  } );
} );
  