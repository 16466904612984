$( () => { 

  // Mutation observer to monitor DOM changes
  const observer = new MutationObserver( handleMutations );

  // Configuration of the mutation observer
  const config = { childList: true, subtree: true };

  // Select the node that will be observed for mutations
  const targetNode = document.querySelector( '.bs-div--form-wrapper' );

  // Start observing the document
  if( targetNode ) {
    observer.observe( targetNode, config );
  } 

  window.addEventListener( 'message', handleMessageEvent );
} );

// Function to add 'active' class if the element is visible and has a value
function addActiveClassHsForm( element ) {
  if ( element && element.style.display !== 'none' ) {
    const selectElement = element.querySelector( '.hs-input' );
    if ( selectElement && selectElement.value.trim() !== '' ) {
      element.classList.add( 'active' );
    }
  }
}

// Function to check if the element is added to the DOM
function addClassForNewElement() {
  const element = document.querySelector( '.hs_country_or_region + div' );
  if ( element ) {
    addActiveClassHsForm( element );
  }
}

// Mutation observer to monitor DOM changes
function handleMutations( mutations ) {
  mutations.forEach( mutation => {
    if ( mutation.type === 'childList' ) {
      addClassForNewElement();
    }
  } );
}

// handle change input focus events in form fields
function handleInputFocusChange( e ) {
  const closestField = $( e.currentTarget ).closest( '.field' );
  if ( $( e.currentTarget ).is( ':focus' ) || $( e.currentTarget ).val() ) {
    closestField.addClass( 'active' );
  } else {
    closestField.removeClass( 'active' );
  }
}

// function for the message event handler
function handleMessageEvent( event ) {
  if ( event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormReady' ) {
    $( '.bs-div--form-wrapper' ).addClass( 'show' );
  }

  try {
    const allowedOrigins = ['https://forms.hsforms.com', window.location.origin];
    if ( event.origin && allowedOrigins.includes( event.origin ) ) {
      if ( event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormReady' ) {
        

        $( document ).on( 'change input focus', '.hs-input', handleInputFocusChange );

        $( document ).on( 'focusout', '.hs-input', function ( e ) {
          const closestField = $( e.currentTarget ).closest( '.field' );
          if ( !$( e.currentTarget ).val() ) {
            closestField.removeClass( 'active' );
          } else {
            closestField.addClass( 'active' );
          }
        } );

        const hsSubmit = document.querySelector( '.bs-div--form-wrapper .hs-submit' );
        const input = hsSubmit.querySelector( 'input[type="submit"]' );

        hsSubmit.addEventListener( 'click', input.click );
      }
    }
  } catch ( e ) {
    console.error( e );
  }
}
