class OurHistoryTimeline {
  constructor( settings ) {
    this.settings = settings;
    this.maxHeight = 533;
    this.parentEl = $( '.bs-section--about-our-history' );
    this.postsWrapper = this.parentEl.find( '.bs-posts__normal-grid' );
    this.postsInner = this.postsWrapper.find( '.bs-posts__normal-row' );
    this.scrollAmount = 200;
  }

  checkPostsInnerHeight() {
    return this.postsInner.outerHeight( true ) > this.maxHeight;
  }
  addArrows() {
    const arrowsEl = `<div class="bs-posts__nav-arrows">
		<span class="bs-posts__nav-arrow bs-posts__nav-arrow--up"></span>
		<span class="bs-posts__nav-arrow bs-posts__nav-arrow--down"></span>
		</div>`;
    const hasMoreHeight = this.checkPostsInnerHeight();
    if ( hasMoreHeight ) {
      this.postsWrapper.parents( '.bs-posts__normal' ).append( arrowsEl );
    }
  }
  scrollElement( scrollValue ) {
    this.postsWrapper.animate(
      {
        scrollTop: this.postsWrapper.scrollTop() + scrollValue,
      },
      500,
      () => { this.checkScrollPosition(); }
    );
  }

  checkScrollPosition() {
    if( this.parentEl.length === 0 ) return;
    const currScrollTop = this.postsWrapper.scrollTop();
    const postInnerHeight = this.postsInner.outerHeight( true );
    $( '.bs-posts__nav-arrow--up' ).toggleClass( 'bs-posts__nav-arrow--disabled', currScrollTop <= 0 );
    $( '.bs-posts__nav-arrow--down' ).toggleClass( 'bs-posts__nav-arrow--disabled',
      currScrollTop >= postInnerHeight - this.maxHeight );
  }

  init() {
    if( this.parentEl.length === 0 ) return;
    const postsCount = this.postsInner.find( '.bs-post' ).length;
    if ( postsCount % 2 === 0 ) this.postsInner.addClass( 'bs-posts__normal-row--count-odd' );
    this.addArrows();
    $( '.bs-posts__nav-arrow--up' ).on( 'click', () => {
      this.scrollElement( -this.scrollAmount );
    } );
    $( '.bs-posts__nav-arrow--down' ).on( 'click', () => {
      this.scrollElement( this.scrollAmount );
    } );
  }
}

const ourHistoryTimeline = new OurHistoryTimeline();
ourHistoryTimeline.init();
$( window ).on( 'scroll load', () => {
  ourHistoryTimeline.checkScrollPosition();
} );
$( '.bs-section--about-our-history .bs-posts__normal-grid' ).on( 'scroll', () => {
  ourHistoryTimeline.checkScrollPosition();
} );

const items = $( '.bs-section--about-our-history .bs-post' );
if( $( '.bs-section--about-our-history .bs-post' ).length ) {
  const options = {
    root: document.querySelector( '.bs-row--about-our-history-posts' ),
    threshold: 0.6
  };
  
  const observer = new IntersectionObserver( entries => {
    entries.forEach( entry => { 
      if ( entry.boundingClientRect.top < 600 ) {
        entry.target.classList.toggle( 'bs-post--nv', !entry.isIntersecting );
      }
    } );
  }, options );
  
  $( items ).each( function(){
    observer.observe( this );
  } );
}
