$( ( $ ) => {
  const aboutLogoSlider = $( '.bs-section--about-our-customers' );
  const clientsLink = $( '.clients-list li a' );
  const togglePlay = ( event, status ) => {
    if( aboutLogoSlider.length ) {
      $( '.clients-slider-wrapper' ).each( function(){
        $( this ).find( '.clients-list' ).css( 'animation-play-state', status );
      } );
    } else {
      $( event.target ).closest( '.start-marquee' ).css( 'animation-play-state', status );
    }
  };
  clientsLink.on( 'mouseenter', ( event ) => {
    togglePlay( event, 'paused' );
  } );
  clientsLink.on( 'mouseleave', ( event ) => {
    togglePlay( event, 'unset' );
  } );
} );
