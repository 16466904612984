import { debounce } from 'lodash';

$( document ).ready( () => {
  let preScrollPos = 0;
  const header = $( '#header' );

  const headerScrollUp = ( currentScrollPos ) => {
    if ( currentScrollPos < preScrollPos && currentScrollPos !== 0 ) {
      header.addClass( 'scrolled-up' );
    } else {
      header.removeClass( 'scrolled-up' );
    }
    preScrollPos = currentScrollPos;
  };

  const debouncedHeaderScrollUp = debounce( headerScrollUp, 100 );

  const scrollHandler = () => {
    const currentScrollPos = $( window ).scrollTop();
    debouncedHeaderScrollUp( currentScrollPos );
  };

  $( window ).scroll( scrollHandler );
} );
