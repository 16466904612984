$( ( $ ) => {
  const duration = 600;
  const footer = $( 'footer' );
  footer
    .find( '.footer-menu > .menu-item-has-children' )
    .append( '<span class="footer-drop-down-helper"></span>' );
  const footerDropDownHelper = footer.find(
    '.footer-menu > .menu-item-has-children span.footer-drop-down-helper'
  );
  footerDropDownHelper.on( 'click', ( e ) => {
    const $this = $( e.target );
    const li = $this.parent();
    const isExpanded = li.hasClass( 'footer-expanded' );
    footer
      .find( '.footer-expanded' )
      .removeClass( 'footer-expanded' )
      .find( '>ul.sub-menu' )
      .slideUp( duration );
    if ( isExpanded ) {
      $this.prev( 'ul.sub-menu' ).slideUp( duration );
    } else {
      li.addClass( 'footer-expanded' );
      $this.prev( 'ul.sub-menu' ).slideDown( duration );
    }
  } );

} );
