// get all logos in the element
const getLogosItem = ( slideParent ) => {
  return slideParent.querySelectorAll( 'ul li' );
};

// generate animation speed
const generateAnimationSpeed = ( elem ) => {
  const elementDataSpeed = elem.children[0].getAttribute( 'animation-duration' );
  return elementDataSpeed / 1000;
};

const commonLogoSliders = document.querySelectorAll( '.clients-slider-wrapper' );

if ( commonLogoSliders.length > 0 ) {
  const logoPlayHandler = ( slidersData ) => {
    const firstSliderParent = slidersData.firstSlider.parent,
      firstSliderLogos = slidersData.firstSlider.logos;

    const desktopLogoCount = firstSliderLogos.length,
      animateSpeed = generateAnimationSpeed( firstSliderParent );
    // set slider animate class if logo count more than 4
    const sliderStartClass =
      desktopLogoCount > 4 ? 'start-marquee' : 'stop-playing';

    firstSliderParent.querySelector( 'ul' ).classList.add( sliderStartClass );
    firstSliderParent.querySelector( 'ul' ).innerHTML = '';

    for ( let i = 0; i < 6; i++ ) {
      const sliderList = firstSliderParent.querySelector( 'ul' );
      const cloneLogosHTML = Array.from( firstSliderLogos )
        .map( ( logo ) => logo.outerHTML )
        .join( '' );
      sliderList.insertAdjacentHTML( 'beforeend', cloneLogosHTML );
    }

    firstSliderParent.querySelector(
      'ul',
    ).style.animationDuration = `${animateSpeed}s`;
  };

  if ( commonLogoSliders.length !== 0 ) {
    // looping all logo slider sections
    commonLogoSliders.forEach( ( ele ) => {
      const logoSliderSingleSection = ele;
      if ( logoSliderSingleSection ) {
        const logoSliders =
          logoSliderSingleSection.querySelectorAll( '.clients-wrap' );
        // collect slide logos
        const firstSlider = logoSliders[0];
        const firstSlideLogos = getLogosItem( firstSlider );
        // create sliders object for modification
        const slidersData = {
          firstSlider: {
            parent: firstSlider,
            logos: firstSlideLogos,
          },
        };
        logoPlayHandler( slidersData );
      }
    } );
  }
}
