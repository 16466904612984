class AboutAccordion {
  constructor() {
    this.accordions = $( '.bs-div--about-accordion-item' );
    this.initialAccordion = $( '.bs-div--about-accordion-item-active' );
    this.accordionToggle = '<span class="about-accordion-toggle-btn"></span>';
    this.accordions.prepend( this.accordionToggle );
    this.accordionToggleBtns = this.accordions.find( '.about-accordion-toggle-btn' );
    this.accordionHeads = this.accordions.find( '.bs-div--about-accordion-title' );
    this.accordionBodies = this.accordions.find( '.bs-div--about-accordion-content' );
  }
  collapseAccordion() {
    this.accordionBodies.slideUp( 500 );
    this.accordions.removeClass( 'bs-div--about-accordion-item-active' );
  }
  expandAccordion( accordion ) {
    const selectedBody = accordion.find( '.bs-div--about-accordion-content' );
    this.accordionBodies.not( selectedBody ).slideUp( 500 );
    this.accordions.not( accordion ).removeClass( 'bs-div--about-accordion-item-active' );

    selectedBody.slideDown( 500 );
    accordion.addClass( 'bs-div--about-accordion-item-active' );
  }
  init() {
    if ( this.accordions.length === 0 ) return;
    this.initialAccordion.find( '.bs-div--about-accordion-content' ).slideDown( 10 );
    this.accordionToggleBtns.on( 'click', e => {
      const clickedAccordion = $( e.target ).closest( '.bs-div--about-accordion-item' );
      if ( clickedAccordion.hasClass( 'bs-div--about-accordion-item-active' ) ) {
        this.collapseAccordion();
        return;
      }
      this.expandAccordion( clickedAccordion );
    } );
  }
}
const aboutAccordions = new AboutAccordion();
aboutAccordions.init();
