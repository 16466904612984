
window.addEventListener( 'hashchange', () => {
  // Get the hash value from the current URL
  const currentHash = window.location.pathname + window.location.hash ;

  // Find all anchor tags on the page
  const allAnchors = document.querySelectorAll( 'a' );

  // Iterate through all anchor tags
  allAnchors.forEach( anchor => {
    if ( anchor.getAttribute( 'href' ) === currentHash ) {
      
      // class adding and removing for header menu
      toggleActiveMenuClasses( anchor, '.mega-menu', '.mega-menu-item' );

      // class adding and removing for footer menu
      toggleActiveMenuClasses( anchor, '.sub-menu', '.current-menu-item' ); 
    }
  } );
} );

window.addEventListener( 'load', () => {

  let path = window.location.pathname + window.location.hash;
  path = path.replace( /\/$/, '' );
  path = decodeURIComponent( path );

  // class adding and removing for header menu
  $( '.mega-menu-item a' ).each( ( _index , el ) => {
    const href = $ ( el ).attr( 'href' );
    if ( href !== undefined && path === href ) {
      toggleActiveMenuClasses( el, '.mega-menu', '.mega-menu-item' );  
    }
  } ); 
  
  // class adding and removing for footer menu
  $( '.current-menu-item a' ).each( ( _index , el ) => {
    const href = $( el ).attr( 'href' );
    if ( href !== undefined && path === href ) {
      toggleActiveMenuClasses( el, '.sub-menu', '.current-menu-item' );     
    }
  } );
} );

function toggleActiveMenuClasses ( obj, menu_selector, item_selector ) {
  $( obj ).closest( menu_selector ).find( item_selector )
    .removeClass( 'section-active-menu-item' );
  $( obj ).parent( item_selector ).addClass( 'section-active-menu-item' );
  $( obj ).closest( menu_selector ).find( item_selector )
    .removeClass( 'mega-current-menu-item' );
}
