'use strict';
( () => {
  const btnOpenPositions = document.querySelectorAll( '[href="#open-position"]' );
  const openPositions = document.querySelectorAll( '.bs-section--open-positions .bs-post__details' );
  const openPositionsSec = document.querySelector( '.bs-section--open-positions' );
  const btnOpenPositionsLoadMore = document.querySelector( '.bs-section--open-positions .bs-posts__load-more-btn' );
  let isHide = true;

  if( btnOpenPositions.length && openPositions.length ) {
    [...btnOpenPositions].forEach( function ( elm ) {
      elm.addEventListener( 'click', function( e ){
        e.preventDefault();
        openPositionsSec.scrollIntoView();
        if ( isHide ) btnOpenPositionsLoadMore.click();
        
        isHide = false;
      } );
      btnOpenPositionsLoadMore.addEventListener( 'click', function( ){
        isHide = false;
      } );
    } );
  }
} )();
