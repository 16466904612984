$( $ => {
  const header = $( 'header' );
  //Add Class on Scroll
  const scrollClass = () => {
    if ( window.pageYOffset >= 1 ) {
      header.addClass( 'page-scrolled' );
      header.removeClass( 'page-at-top' );
    } else {
      header.removeClass( 'page-scrolled' );
      header.addClass( 'page-at-top' );
    }
  };

  header.find( '.menu-item a' ).each( ( index, element ) => {
    const $this = $( element );
    const $text = $this.text();
    $this.attr( { 'data-text': $text } );
  } );

  $( '.navbar-toggler' ).on( 'click', () => {
    $( 'body' ).toggleClass( 'menu-expanded' );
  } );

  $( window ).bind( 'load scroll', () => {
    scrollClass();
  } );
} );
