/* global debounce */
$( $ => {
  const wrapperDiv = $( '.bs-section--accordion-cards' );
  if ( wrapperDiv.length === 0 ) return;
  const isMobile = () => {
    return window.matchMedia( '(max-width: 767.98px)' ).matches;
  };
  if ( wrapperDiv.length === 0 || !isMobile() ) { return; }
  const blurbs = wrapperDiv.find( '.bs-div--accordion-cards' );
  const descriptions = wrapperDiv.find( 'p' );
  const images = wrapperDiv.find( '.wp-block-e25m-svg-animation-block' );
  const duration = 200;
  const accordionToggle = wrapperDiv.find( '.bs-div--accordion-cards' );

  const expandBlurb = ( blurb ) => {
    const selectedDescription = blurb.find( 'p' );
    const selectedImage = blurb.find( '.wp-block-e25m-svg-animation-block' );

    descriptions.not( selectedDescription ).slideUp( duration );
    images.not( selectedImage ).slideUp( duration );
    blurbs.not( blurb ).removeClass( 'expanded' );
    selectedDescription.slideDown( duration );
    selectedImage.slideDown( duration );
    blurb.addClass( 'expanded' );
  };

  const collapseBlurb = blurb => {
    const selectedDescription = blurb.find( 'p' );
    const selectedImage = blurb.find( '.wp-block-e25m-svg-animation-block' );
    selectedDescription.slideUp( duration );
    selectedImage.slideUp( duration );
    blurb.removeClass( 'expanded' );
  };

  const init = () => {
    expandBlurb( blurbs.eq( 0 ) ); 
			
    // check first accordion is in view
    const observer = new IntersectionObserver( {
    }, {
      root: null,
      rootMargin: '0px',
      threshold: 1, 
    } );
    observer.observe( blurbs[0] );
  };

  accordionToggle.on( 'click', e => {
    const blurb = $( e.target ).closest( '.bs-div' );
    if ( blurb.hasClass( 'expanded' ) ) {
      collapseBlurb( blurb );
      return;
    }
    expandBlurb( blurb );
  } );

  init();
  $( window ).on( 'orientationchange', debounce( init, 100 ) );
} );