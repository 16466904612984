'use strict';
( () => {
  const hAnim = ( sentence ) => {
    sentence?.forEach( function ( el ) {
      observer.observe( el );
      el.classList.add( 'text-animation' );
      el.dataset.splitText = el.textContent;
      el.innerHTML = el.textContent
        .split( /\s/ )
        .map( function ( word, index ) {
          return word
            .split( '-' )
            .map( function ( word ) {
              return `<span class='word' style='transition-delay:${index/8}s'>` + word + '</span>';
            } )
            .join( '<span class="hyphen">-</span>' );
        } )
        .join( ' ' );
    } );
  };

  const observer = new IntersectionObserver( entries => {
    entries.forEach( entry => {
      const bcrY = entry.boundingClientRect.y;
      if ( bcrY > 0 && entry.isIntersecting ) {
        entry.target.classList.add( 'text-animation--up' );
        entry.target.classList.remove( 'text-animation--down' );
        entry.target.classList.remove( 'text-animation--not-visible' );
      }
      if ( bcrY <= 0 && entry.isIntersecting ) {
        entry.target.classList.add( 'text-animation--down' );
        entry.target.classList.remove( 'text-animation--up' );
        entry.target.classList.remove( 'text-animation--not-visible' );
      }
      if ( !entry.isIntersecting ) {
        entry.target.classList.add( 'text-animation--not-visible' );
        entry.target.classList.remove( 'text-animation--up' );
        entry.target.classList.remove( 'text-animation--down' );
      }
    } );
  } );

  const headingTwo = document.querySelectorAll( 
    'section h2:not(section.bs-section--privacy-content h2, section.bs-section--terms-content h2)' 
  );
  hAnim( headingTwo );

} )();
